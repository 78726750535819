.exercise_inputs_title {
  border: 1px solid white;
  border-radius: 8px;
  background-color: #40005d;
  width: 60%;
  text-align: center;
  margin: 15px auto 10px auto;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .exercise_inputs_title {
    font-size: 10px;
  }
}
