.exercise_row {
  display: flex;
  flex-flow: row;
  height: 40px;
  margin-bottom: 10px;
}

.exercise_label {
  line-height: 40px;
  margin-left: 20px;
  border: white 1px solid;
  border-radius: 8px;
  background-color: #2a2a2a;
  width: 200px;
  text-align: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .exercise_label {
    margin-left: 10px;
  }
}
