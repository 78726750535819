.exercise_lifts {
  background-color: #2a2a2a;
  width: 100%;
  border: white 1px solid;
  border-radius: 8px;
  margin: 0px 25px 0px 25px;

  line-height: 40px;
  border: white 1px solid;
  border-radius: 8px;
  background-color: #2a2a2a;
  text-align: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .exercise_lifts {
    font-size: 12px;
    margin: 0px 10px 0px 10px;
  }
}
