.exercise_inputs_list {
  display: flex;
  flex-flow: wrap;
  background-color: #4b4b4b;
  border-radius: 12px;
  margin-bottom: 20px;
}

.exercise_input {
  margin: auto;
}
