.exercise_input_container {
  display: flex;
  padding: 2px;
  margin: auto;
  height: 40px;
  width: 35%;
  background-color: #2a2a2a;
  border: 1px solid #ececec;
  border-radius: 12px;
  margin: 10px auto 10px auto;
}

.exercise_input_title {
  text-align: left;
  width: 100px;
  margin: auto;
  font-size: 22px;
}

.exercise_input_field {
  margin: auto;
  border-radius: 7px;
  height: 20px;
  text-align: left;
  width: 50%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .exercise_input_title {
    font-size: 17px;
  }

  .exercise_input_field {
    width: 30px;
  }
}
